import { default as _91_46_46_46slug_93Sb3zmP9EPhMeta } from "/opt/atlassian/pipelines/agent/build/pages/[...slug].vue?macro=true";
import { default as _404VI8KNBPx5SMeta } from "/opt/atlassian/pipelines/agent/build/pages/404.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/404.vue").then(m => m.default || m)
  }
]